/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import React from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SaltHero from "../images/salt-hero.png"
import HeroSection from "../containers/hero-section-dynamic"
import Instagram from "../containers/instagram"
import email from "../images/email.png"
import phone from "../images/call.png"
import direction from "../images/direction.png"
import location from "../images/location.png"

export default function Example({ data }) {
  const contactDetails = [
    {
      name: "Info",
      email: {
        text: "hi@zrcebeach.com",
        href: "mailto:hi@zrcebeach.com",
        icon: email,
      },
      telephone: {
        text: "00385 91 112 2241",
        href: "tel:+385911122241",
        icon: phone,
      },
    },
    {
      name: "Location",
      email: {
        text: "Zrce beach, 53291, Novalja",
        href: "https://goo.gl/maps/YDJPbewWz9jUq9Up6",
        icon: direction,
      },
      telephone: {
        text: "Get directions",
        href: "https://goo.gl/maps/YDJPbewWz9jUq9Up6",
        icon: location,
      },
    },
  ]
  return (
    <Layout
      title={"Zrce Beach"}
      ctaColor="bg-menublue-50"
      textCtaColor="text-white"
      color="bg-menublue-50"
      onlyMainLogoSimple
      fullHeader
    >
      <Seo
        title="Contact Us"
        description="Do you have any questions or need help with your reservation? Contact us and we will get back to you as soon as possible."
      />
      <HeroSection
        small
        image={data.strapiContactUs.backgroundImage}
        smallImage={data.strapiContactUs.backgroundImageMobile}
      />
      <div className="relative bg-menublue-50 rounded-t-4xl">
        <div className="relative py-16 px-4 sm:py-24 sm:px-6 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:px-0 lg:py-32">
          <div className="lg:pr-8">
            <div className="mx-auto max-w-md sm:max-w-lg lg:mx-0">
              <h1 className="text-3xl font-primary-bold tracking-tight sm:text-4xl text-white">
                CONTACT
              </h1>
              <form
                action="#"
                method="POST"
                className="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
              >
                <div>
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-primary-bold text-white pb-2"
                  >
                    First Name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      className="block w-full px-2 py-3 font-primary-book rounded-xl border-gray-300 shadow-sm focus:border-yellowdark-50 focus:ring-yellowdark-50 sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-primary-bold text-white pb-2"
                  >
                    Last name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      autoComplete="family-name"
                      className="block w-full px-2 py-3 font-primary-book rounded-xl border-gray-300 shadow-sm focus:border-yellowdark-50 focus:ring-yellowdark-50 sm:text-sm"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="email"
                    className="block text-sm font-primary-bold text-white pb-2"
                  >
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      className="block w-full px-2 py-3 font-primary-book rounded-xl border-gray-300 shadow-sm focus:border-yellowdark-50 focus:ring-yellowdark-50 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label
                      htmlFor="how-can-we-help"
                      className="block text-sm font-primary-bold text-white pb-2"
                    >
                      Message
                    </label>
                    {/* <span
                      id="how-can-we-help-description"
                      className="text-sm text-gray-500"
                    >
                      Max. 500 characters
                    </span> */}
                  </div>
                  <div className="mt-1">
                    <textarea
                      id="how-can-we-help"
                      name="how-can-we-help"
                      aria-describedby="how-can-we-help-description"
                      rows={11}
                      className="block w-full px-2 py-3 font-primary-book rounded-xl border-gray-300 shadow-sm focus:border-yellowdark-50 focus:ring-yellowdark-50 sm:text-sm"
                      defaultValue={""}
                    />
                  </div>
                </div>

                <div className="text-left sm:col-span-2">
                  {/* <button
                    type="submit"
                    className={`inline-block w-full items-center justify-center px-8 py-2 border-2  ${contactCtaBorderColor} ${contactCtaColor} text-sm font-primary-bold transition-all rounded-full hover:text-purplebglight-50 hover:bg-white bg-transparent md:py-4 md:text-sm md:px-10`}
                  >
                    Reservation
                  </button> */}
                  {/* <div className="relative flex items-start   mt-0 mb-10">
                    <div className="flex h-5 items-center">
                      <input
                        id="offers"
                        name="offers"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="offers"
                        className="font-medium text-white font-inter"
                      >
                        Get notified when a candidate accepts or rejects an
                        offer.
                      </label>
                      <p className="text-gray-500"></p>
                    </div>
                  </div> */}
                  {/* <p className="mb-4 text-xs px-0 mx-auto text-center lg:text-center text-white font-inter">
                    We care about the protection of your data. Read our{" "}
                    <a href="#" className="font-medium text-white underline">
                      Privacy Policy.
                    </a>
                  </p> */}
                  <button
                    type="submit"
                    className="inline-flex w-full lg:w-[unset]  items-center justify-center px-8 py-3 border-2 text-sm text-white font-primary-bold transition-all rounded-full hover:text-purplebglight-50 hover:bg-white bg-transparent md:py-3 md:text-sm md:px-10"
                  >
                    Send
                  </button>

                  {/* <button
                    type="submit"
                    className="inline-flex justify-center rounded-full   px-8 py-2 text-sm font-primary-bold text-white border-2 border-white  focus:outline-none focus:ring-2 focus:ring-yellowdark-50 focus:ring-offset-2"
                  >
                    Send
                  </button> */}
                </div>
              </form>
            </div>
          </div>
          <div>
            <div className="space-y-10 md:grid md:grid-cols-2 md:gap-x-14 md:gap-y-6 md:space-y-0 pb-16">
              <div className="mt-16 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:col-span-2 lg:mt-0">
                {contactDetails.map((item, id) => (
                  <div key={id}>
                    <h3
                      className={`text-2xl mb-8 font-primary-bold text-white  `}
                    >
                      {item.name}
                    </h3>
                    <dl className={`mt-2 text-lg text-white`}>
                      <div className="flex  items-center">
                        <dt className="sr-only">Email</dt>
                        <dd>
                          <img className="w-5 mr-3" src={item.email.icon} />
                        </dd>
                        <a href={item.email.href}>{item.email.text}</a>
                      </div>
                      <div className="mt-4 flex  items-center">
                        <dt className="sr-only">Phone number</dt>
                        <dd>
                          <img className="w-5 mr-3" src={item.telephone.icon} />
                        </dd>
                        <a href={item.telephone.href}>{item.telephone.text}</a>
                      </div>
                    </dl>
                  </div>
                ))}
              </div>
            </div>
            <iframe
              className="lg:px-0 lg:m-0 lg:relative  aspect-auto mx-auto text-right w-full h-[350px] rounded-2xl border-white border-2"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11374.930159406305!2d14.9133953!3d44.54110864999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476249ffab0182e1%3A0x22e47d9941fc2d53!2sZrce%20Festival%20Beach!5e0!3m2!1shr!2srs!4v1666004519737!5m2!1shr!2srs"
              // style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
      {/* <Instagram ellipse bgColor="#80D361" title={"zrcebeach_official"} /> */}
    </Layout>
  )
}
export const pageQuery = graphql`
  query MyQueryFirst {
    strapiContactUs {
      backgroundImage {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 80, placeholder: DOMINANT_COLOR)
          }
        }
      }
      backgroundImageMobile {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 80, placeholder: DOMINANT_COLOR)
          }
        }
      }
    }
  }
`
